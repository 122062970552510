import type { parseUrl } from '@seek/melways-router-utils';
import type { AlternativeName } from '@seek/melways-sites';
import { createContext } from 'react';

/**
 * These helper types are used to make the type output when hovering more usefully informative.
 *
 * Rather than rendering the method of derivation (`Omit<...` etc), it will show the actual keys and types.
 *
 * These are taken from colinhacks/zod.
 * https://github.com/colinhacks/zod/blob/3e4f71e857e75da722bd7e735b6d657a70682df2/src/helpers/util.ts#L70-L71
 *
 * To get useful types like this everywhere, consider the prettify-typescript VSCode extension.
 * https://marketplace.visualstudio.com/items?itemName=MylesMurphy.prettify-ts
 */
type Identity<T> = T;
type Flatten<T> = Identity<{ [k in keyof T]: T[k] }>;

type ParseUrl = ReturnType<typeof parseUrl>;

type MelwaysInformation = Flatten<
  Omit<ParseUrl, 'url' | 'classification'> &
    ParseUrl['classification'] & { alternative?: AlternativeName }
>;

export const MelwaysContext = createContext<MelwaysInformation | null>(null);
