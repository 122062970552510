import { parseUrlWithSite } from '@seek/melways-router-utils';
import {
  type AlternativeName,
  type SiteName,
  isMelwaysSiteName,
} from '@seek/melways-sites';
import React, { type ReactNode, useMemo } from 'react';

import { MelwaysContext } from '../context';

export interface MelwaysServerProviderProps {
  url: string | URL;
  site: SiteName;
  staging?: boolean;
  alternative?: AlternativeName;
  children: ReactNode;
}

export const MelwaysServerProvider = ({
  url,
  site,
  staging = false,
  alternative,
  children,
}: MelwaysServerProviderProps) => {
  if (!isMelwaysSiteName(site)) {
    throw new Error('MelwaysServerProvider requires a valid site name.');
  }

  const contextValue = useMemo(() => {
    const { classification, ...rest } = parseUrlWithSite({
      url,
      site,
      staging,
    });

    return {
      ...rest,
      ...classification,
      ...(alternative ? { alternative } : {}),
    };
  }, [url, site, staging, alternative]);

  return (
    <MelwaysContext.Provider value={contextValue}>
      {children}
    </MelwaysContext.Provider>
  );
};
