import {
  type AlternativeName,
  getClassificationFromSiteName,
  getHostName,
  getLanguageFromLocale,
  getLocale,
  type Language,
  type Locale,
  type SiteName,
} from '@seek/melways-sites';
import React, { type ReactNode, useMemo } from 'react';

import { MelwaysContext } from '../context';

export interface MelwaysStaticProviderProps {
  path: string;
  language: Language | Locale;
  site: SiteName;
  staging?: boolean;
  alternative?: AlternativeName;
  children: ReactNode;
}

export const MelwaysStaticProvider = ({
  site,
  staging = false,
  language: languageProp,
  path,
  alternative,
  children,
}: MelwaysStaticProviderProps) => {
  const contextValue = useMemo(() => {
    const language = getLanguageFromLocale(languageProp);
    const locale = getLocale(site, language);

    const classification = getClassificationFromSiteName(site);

    return {
      site,
      staging,
      language,
      pathWithoutLanguage: path,
      hostname: getHostName({ site, staging }),
      locale,
      ...classification,
      ...(alternative ? { alternative } : {}),
    };
  }, [site, staging, languageProp, path, alternative]);

  return (
    <MelwaysContext.Provider value={contextValue}>
      {children}
    </MelwaysContext.Provider>
  );
};
