import {
  type Classification,
  getAllHostNames,
  getHostName,
  getLocale,
  getSiteNameFromHostname,
  type Language,
  type Locale,
  siteConfig,
  type SiteName,
} from '@seek/melways-sites';

import { getLanguageFromPath } from './get-lang-from-path';

type ParsedMelwaysContext = {
  hostname: string;
  pathWithoutLanguage: string;
  site: SiteName;
  staging: boolean;
  language: Language;
  locale: Language | Locale;
  classification: Classification;
  url: URL;
};

const ensureUrlObject = (url: string | URL, hostname?: string): URL => {
  if (url instanceof URL) {
    return url;
  }

  try {
    const base = hostname ? `https://${hostname}` : undefined;
    return new URL(url, base);
  } catch (e) {
    if (
      e instanceof Error &&
      e.message.includes('is not a valid URL') &&
      url.startsWith('/') &&
      !hostname
    ) {
      // This error will only throw when the hostname is not supplied for a relative path.
      // It will only occur when called from the parseUrl function.
      throw new Error(
        'parseUrl needs a full URL including domain. Relative paths are not accepted.',
      );
    }

    throw e;
  }
};

export const parseUrl = (url: string | URL): ParsedMelwaysContext => {
  const inputUrl = ensureUrlObject(url);
  const site = getSiteNameFromHostname(inputUrl.hostname);

  return {
    ...getMelwaysContextFromParsedValues({
      site,
      pathName: inputUrl.pathname,
    }),
    staging: getAllHostNames({
      site,
      staging: true,
    }).includes(inputUrl.hostname),
    hostname: inputUrl.hostname,
    url: inputUrl,
  };
};

export const parseUrlWithSite = ({
  url,
  site,
  staging = false,
}: {
  url: string | URL;
  site: SiteName;
  staging?: boolean;
}): ParsedMelwaysContext => {
  const hostname = getHostName({ site, staging });
  const inputUrl = ensureUrlObject(url, hostname);

  return {
    ...getMelwaysContextFromParsedValues({
      pathName: inputUrl.pathname,
      site,
    }),
    staging,
    url: inputUrl,
    hostname,
  };
};

const getMelwaysContextFromParsedValues = ({
  site,
  pathName,
}: {
  site: SiteName;
  pathName: string;
}): Omit<ParsedMelwaysContext, 'staging' | 'url' | 'hostname'> => {
  const config = siteConfig[site];

  const { language = 'en', pathWithoutLanguage } = getLanguageFromPath({
    path: pathName,
    languages: config.languages,
    site,
  });

  const locale = getLocale(site, language);

  return {
    site,
    language,
    locale,
    pathWithoutLanguage,
    classification: config.classification,
  };
};
