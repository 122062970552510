import { parseUrlWithSite } from '@seek/melways-router-utils';
import {
  type AlternativeName,
  isMelwaysSiteName,
  type SiteName,
} from '@seek/melways-sites';
import React, { type ReactNode, useMemo } from 'react';

import { MelwaysContext } from '../context';

export interface MelwaysClientProviderProps {
  site: SiteName;
  staging?: boolean;
  alternative?: AlternativeName;
  children: ReactNode;
  /*
   * The current location path of your application.
   *
   * Provide the path if you want your client site routing to update the Melways language and locale based on the current path.
   *
   * If not provided, the current window location will be used to determine the Melways context.
   */
  path?: string;
}

export const MelwaysClientProvider = ({
  site,
  staging = false,
  alternative,
  children,
  path,
}: MelwaysClientProviderProps) => {
  if (!window) {
    throw new Error(
      'MelwaysClientProvider must be used on the client side only',
    );
  }

  if (!isMelwaysSiteName(site)) {
    throw new Error('MelwaysClientProvider requires a valid site name.');
  }

  const url = path || window.location.href;

  const value = useMemo(() => {
    const { classification, ...rest } = parseUrlWithSite({
      url,
      site,
      staging,
    });

    return {
      ...rest,
      ...classification,
      ...(alternative ? { alternative } : {}),
    };
  }, [site, staging, alternative, url]);

  return (
    <MelwaysContext.Provider value={value}>{children}</MelwaysContext.Provider>
  );
};
