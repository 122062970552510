import {
  isNonStandardHomepageLanguageSite,
  nonStandardHomepageDefaultLanguages,
} from '@seek/melways-common-types';
import type { Language, SiteName } from '@seek/melways-sites';

export const getLanguageFromPath = ({
  path,
  languages,
  site,
}: {
  path: string;
  languages: false | Language[];
  site: SiteName;
}): { language?: Language; pathWithoutLanguage: string } => {
  /**
   * This section has been added to support the hack of having the homepage for
   * non-English sites have no suffix (e.g. / instead of /th).
   *
   * See PR and JIRA ticket for more detail.
   *
   * https://github.com/SEEK-Jobs/melways/pull/571
   * https://myseek.atlassian.net/browse/UPH-401
   */

  // If the path is the homepage and the site is in the list of non-standard homepages we return its override value.

  if (path === '/' && isNonStandardHomepageLanguageSite(site)) {
    return {
      language: nonStandardHomepageDefaultLanguages[site],
      pathWithoutLanguage: '/',
    };
  }
  /** </hackery> */

  if (path === '/' || path.indexOf('/') !== 0 || !languages) {
    return {
      pathWithoutLanguage: path,
    };
  }

  const secondSlashIndex = path.indexOf('/', 1);

  const possibleLanguage =
    secondSlashIndex === -1
      ? path.slice(1)
      : path.slice(1, path.indexOf('/', 1));

  if ((languages as string[]).indexOf(possibleLanguage) === -1) {
    return {
      pathWithoutLanguage: path,
    };
  }

  return {
    language: possibleLanguage as Language,
    pathWithoutLanguage:
      secondSlashIndex === -1 ? '/' : path.slice(secondSlashIndex),
  };
};
