import { createUrlResolver } from '@seek/melways-sites';
import { useContext } from 'react';

import { MelwaysContext } from './context';

export const useMelwaysInfo = () => {
  const melwaysContext = useContext(MelwaysContext);

  if (melwaysContext === null) {
    throw new Error('useMelwaysInfo must be used within a MelwaysProvider');
  }

  return melwaysContext;
};

export type UseMelwaysLinkProps =
  | Parameters<ReturnType<typeof createUrlResolver>>[0]
  | string;

type UrlResolverFunction = ReturnType<typeof createUrlResolver>;

export function useMelwaysLink(linkProps: UseMelwaysLinkProps): string;
export function useMelwaysLink(linkProps: UseMelwaysLinkProps[]): string[];
export function useMelwaysLink(): UrlResolverFunction;
export function useMelwaysLink(
  linkProps?: UseMelwaysLinkProps | UseMelwaysLinkProps[],
) {
  const melwaysInfo = useMelwaysInfo();

  const urlResolver = createUrlResolver(melwaysInfo);

  if (!linkProps) {
    return urlResolver;
  }

  const urlStringResolver = (link: UseMelwaysLinkProps) => {
    const linkToResolve = typeof link === 'string' ? { path: link } : link;
    return urlResolver(linkToResolve);
  };

  if (Array.isArray(linkProps)) {
    return linkProps.map(urlStringResolver);
  }

  return urlStringResolver(linkProps);
}
